export class Accordion {
	detailsElement: HTMLDetailsElement;
	summaryElement: HTMLElement;
	content: HTMLElement[];
	animation: Animation | null;
	isCollapsing: boolean;
	isExpanding: boolean;

	constructor(detailsElement: HTMLDetailsElement) {
		this.detailsElement = detailsElement;
		this.summaryElement = detailsElement.querySelector("summary")!;
		this.content = Array.from(detailsElement.children).filter(e => e !== this.summaryElement) as HTMLElement[];

		this.animation = null;
		this.isCollapsing = false;
		this.isExpanding = false;
		this.summaryElement.addEventListener("click", e => {
			e.preventDefault();
			this.toggle();
		});
	}

	toggle() {
		this.detailsElement.style.overflow = "hidden";

		if (this.isCollapsing || !this.detailsElement.open) {
			this.detailsElement.style.height = `${this.detailsElement.offsetHeight}px`;
			this.detailsElement.open = true;
			window.requestAnimationFrame(() => this.expand());
		} else if (this.isExpanding || this.detailsElement.open) {
			this.collapse();
		}
	}

	open() {
		if (this.isCollapsing || !this.detailsElement.open) {
			this.detailsElement.style.height = `${this.detailsElement.offsetHeight}px`;
			this.detailsElement.open = true;
			window.requestAnimationFrame(() => this.expand());
		}
	}

	close() {
		if (this.isExpanding || this.detailsElement.open) {
			this.collapse();
		}
	}

	private expand() {
		this.isExpanding = true;
		const startHeight = `${this.detailsElement.offsetHeight}px`;
		const endHeight = `${this.detailsElement.offsetHeight + this.content.map(e => e.offsetHeight).reduce((prev, curr) => prev + curr, 0)}px`;

		if (this.animation) {
			this.animation.cancel();
		}

		this.animation = this.detailsElement.animate({
			height: [startHeight, endHeight]
		}, {
			duration: 400,
			easing: "ease-out"
		});

		this.animation.onfinish = () => this.onAnimationFinish(true);
		this.animation.oncancel = () => this.isExpanding = false;
	}

	private collapse() {
		this.isCollapsing = true;

		const detailsStyle = window.getComputedStyle(this.detailsElement);
		const startHeight = `${this.detailsElement.offsetHeight}px`;
		const endHeight = `${this.summaryElement.offsetHeight + parseFloat(detailsStyle.paddingTop) + parseFloat(detailsStyle.paddingBottom) }px`;

		if (this.animation) {
			this.animation.cancel();
		}

		this.animation = this.detailsElement.animate({
			height: [startHeight, endHeight]
		}, {
			duration: 400,
			easing: "ease-out"
		});

		this.animation.onfinish = () => this.onAnimationFinish(false);
		this.animation.oncancel = () => this.isCollapsing = false;
	}

	private onAnimationFinish(open: boolean) {
		this.detailsElement.open = open;
		this.animation = null;
		this.isCollapsing = false;
		this.isExpanding = false;
		this.detailsElement.style.height = "";
		this.detailsElement.style.overflow = "";
	}
}
