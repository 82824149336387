import "../Styles/index.scss";

import { Fancybox } from "@fancyapps/ui";
import { Accordion } from "./accordion";
import svelteRetag from "svelte-retag";
import ContactForm from "./contact-form.svelte";

Fancybox.bind(".is-zoomable");

var heightOfRightBox = document.querySelector<HTMLElement>(".overviewBoxRightProductText")?.clientHeight;
document.getElementById("content")!.style.minHeight = `${(heightOfRightBox || 0) + 360}px`;

for (let element of document.querySelectorAll("details")) {
	new Accordion(element);
}

svelteRetag({
	component: ContactForm,
	tagname: "contact-form",
});
