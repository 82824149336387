<script lang="ts">
	import { onMount, tick } from "svelte";
	import ky from "ky";
	import { create, enforce, test, group, skip } from "vest";
	import "vest/enforce/email";
	import toast, { Toaster } from "@leodog896/svelte-french-toast";
	import { TextInput, Button, Textarea, SvelteUIProvider, createTheme } from "@svelteuidev/core";
	import type { ProblemDocument } from "http-problem-details";

	import ToastContent from "./toast-content.svelte";
	import { ValidatingStore, type ValidationProblemDetails } from "./validating-store";
	import { FormState, theme } from "./utils";

	interface ContactRequest {
		fullname: string;
		email: string;
		phone: string;
		message: string;
		source: string;
	}

	const initialFormData: ContactRequest = {
		fullname: "",
		email: "",
		phone: "",
		message: "",
		source: "",
	};

	export let source: string;
	export let color: string;

	let state: FormState = FormState.Ready;
	let token: string = "";

	const store = new ValidatingStore(initialFormData, (data: ContactRequest) => {
		test("fullname", "Bitte geben Sie Ihren Namen an", () => {
			enforce(data.fullname).isNotBlank();
		});
		test("email", () => {
			enforce(data.email)
				.message("Bitte geben Sie Ihre E-Mail-Adresse an")
				.isNotBlank()
				.message("Bitte geben Sie eine gültige E-Mail-Adresse an")
				.isEmail();
		});
		test("message", "Bitte geben Sie eine Nachricht ein", () => {
			enforce(data.message).isNotBlank();
		});
	});

	onMount(() => {
		let tokenElement = document.getElementById("RequestVerificationToken");
		if (tokenElement == null || tokenElement.textContent == null) {
			throw new Error("Could not load XSRF token.");
		}
		token = JSON.parse(tokenElement.textContent);
	});

	async function submitAsync() {
		if (state !== FormState.Ready) {
			return;
		}
		if (!store.validate()) {
			return;
		}

		try {
			state = FormState.Loading;
			let response = await ky.post(`/api/contact`, {
				headers: { RequestVerificationToken: token },
				json: {
					...store.getData(),
					source: source,
				},
				throwHttpErrors: false,
			});

			if (response.ok) {
				state = FormState.Success;
				store.clear();
				toast.success(ToastContent, {
					props: {
						messageHtml: `Herzlichen Dank für Ihre Anfrage. Wir werden uns in Kürze bei Ihnen melden.`,
					},
				});
			} else if (response.status === 400) {
				state = FormState.Ready;
				let problem = await response.json<ValidationProblemDetails>();
				store.setExternalErrors(problem);
			} else {
				let problem = await response.json<ProblemDocument>();
				toast.error(ToastContent, {
					props: {
						problem,
						messageHtml: `Hoppla! Leider ist etwas schief gelaufen. Bitte nehmen Sie mit uns unter 033 336 80 85 Kontakt auf und berichten Sie uns von diesem Fehler. Herzlichen Dank!`,
					},
				});
				state = FormState.Error;
			}
		} catch (e) {
			state = FormState.Error;
			toast.error(ToastContent, {
				props: {
					messageHtml: `Hoppla! Leider ist etwas schief gelaufen. Bitte nehmen Sie mit uns unter 033 336 80 85 Kontakt auf und berichten Sie uns von diesem Fehler. Herzlichen Dank!`,
				},
			});
		}
	}
</script>

<SvelteUIProvider {theme}>
	<div class="contact-form">
		<p>
			<TextInput label="Vor- und Nachname" autocomplete="name" invalid={$store.fullname.hasErrors} error={$store.fullname.errors.join(", ")} on:blur={() => $store.fullname.touch()} bind:value={$store.fullname.value} />
		</p>
		<p>
			<TextInput label="Telefon" autocomplete="tel" invalid={$store.phone.hasErrors} error={$store.phone.errors.join(", ")} on:blur={() => $store.phone.touch()} bind:value={$store.phone.value} />
		</p>
		<p>
			<TextInput label="E-Mail" autocomplete="email" invalid={$store.email.hasErrors} error={$store.email.errors.join(", ")} on:blur={() => $store.email.touch()} bind:value={$store.email.value} />
		</p>
		<p>
			<Textarea label="Nachricht" rows={6} cols={40} invalid={$store.message.hasErrors} error={$store.message.errors.join(", ")} on:blur={() => $store.message.touch()} bind:value={$store.message.value} />
		</p>
		<p>
			<Button class={color} size="md" loading={state === FormState.Loading} on:click={() => submitAsync()}>Absenden</Button>
		</p>
	</div>
</SvelteUIProvider>
<Toaster />
